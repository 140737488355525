$primary: #d6b162;
$primary100: #4c6fff;

$primaryDark: #0043a9;
$primaryLight: #3380f4;

$secondary: #425466;
$secondaryDark: #272f33;
$secondaryLight: #60696d;

$error: #ff413a;
$errorDark: #a20e00;
$errorLight: #ec4333;

$warning: #f4a100;
$warningDark: #aa7000;
$warningLight: #f6b333;

$info: #00cfd5;
$infoDark: #009095;
$infoLight: #33d8dd;

$success: #00ac69;
$successDark: #007849;
$successLight: #33bc87;

$grey300: #425466;
$dark: #343a40;

$bg100: #f8f8f9;

// Text Colors

//$textPrimary: #69707a;
//$textSecondary: #4a515b;
//$textDark: #212832;
//$textHint: #00000061;

$textPrimary: #242c3a;
$textSecondary: #1b212c;
$textDark: #12171e;
$textHint: #00000061;

// Text Dark Color
$textDarkPrimary: #bbc0c7;
$textDarkSecondary: #babfc9;
$textDarkDark: #fff;
$texDarkHint: #94919161;

// Background Color
$backgound: #f0f2f8;
$backgoundDark: #181e2b;

// Paper Color
$paper: #ffffff;
$paperDark: #232b38;

$menuHover: #e3f2fd;

// this will use in javascript
:export {
  primary: $primary;
  primaryDark: $primaryDark;
  primaryLight: $primaryLight;
  primary100: $primary100;

  secondary: $secondary;
  secondaryDark: $secondaryDark;
  secondaryDark: $secondaryDark;

  error: $error;
  errorDark: $errorDark;
  errorLight: $errorLight;

  warning: $warning;
  warningDark: $warningDark;
  warningLight: $warningLight;

  info: $info;
  infoDark: $infoDark;
  infoLight: $infoLight;

  success: $success;
  successDark: $successDark;
  successLight: $successLight;

  grey300: $grey300;

  bg100: $bg100;

  // Text Colors
  textPrimary: $textPrimary;
  textSecondary: $textSecondary;
  textDark: $textDark;
  textHint: $textHint;

  // Text Dark Colors
  textDarkPrimary: $textDarkPrimary;
  textDarkSecondary: $textDarkSecondary;
  textDarkDark: $textDarkDark;
  textDarkHint: $texDarkHint;

  // Background Color
  backgound: $backgound;
  backgoundDark: $backgoundDark;

  // Paper Color
  paper: $paper;
  paperDark: $paperDark;
  menuHover: $menuHover;
}
