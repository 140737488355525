iframe {
    display: none !important;
}

iframe.player {
    display: block !important;
}

label#jenis-project {
    margin-top: -8px;
    margin-left: 14px;
}